import React from 'react';
import { colors, mq, fontSizes } from '../../theme';
import Container from '../Container';
import { Paragraph, Heading3, Heading2 } from '../Heading/Text';

const Referencement = () => {
  return (
    <>
      <div
        css={{
          backgroundColor: colors.neutralDarker,
          color: colors.neutralWhite
        }}
      >
        <Heading2
          light
          css={mq({
            textAlign: 'center',
            margin: 'auto',
            paddingTop: 80,
            color: colors.neutralWhite
          })}
        >
          Le <span>meilleur logiciel devis facture batiment</span>
          <br /> du marché pour votre entreprise
        </Heading2>
        <Container css={mq({ flexDirection: ['row', 'column'] })}>
          <Container.ContentLeft css={mq({ paddingRight: [60, 0] })}>
            <Heading3
              css={{
                color: colors.brandColor,
                lineHeight: '120%'
              }}
            >
              Tous vos documents à partir d’un devis ultra-précis
            </Heading3>
            <Paragraph
              css={{
                fontSize: fontSizes.medium
              }}
            >
              Web2vi va plus loin que le simple logiciel devis facture batiment.
              <strong> C’est un logiciel gestion de batiment complet </strong>
              conçu par et pour les entreprises du batiment et les artisans btp.
              <br /> Également un <strong>logiciel suivi de chantier</strong>,
              il permet de contrôler vos affaires du métré à l’export comptable
              en proposant l’édition de feuilles de vente, fiches
              d&apos;instructions chantier, bons de commandes, demande de prix,
              récapitulatifs déboursés, journaux de banque, exports de journal
              de vente, bordereaux de remise de chèques etc.
            </Paragraph>
            <Heading3
              css={{
                color: colors.brandColor,
                lineHeight: '120%'
              }}
            >
              Un gain de temps considérable sur le métré et le devis{' '}
            </Heading3>
            <Paragraph
              css={{
                fontSize: fontSizes.medium
              }}
            >
              Gagnez du temps grâce à notre{' '}
              <strong>logiciel chiffrage batiment innovant</strong>. Avec notre{' '}
              <strong>
                solution de métré 3D via drone et leurs maquettes 3D
              </strong>{' '}
              vous avez la possibilité d’effectuer des mesures précises en
              sécurité et en 2 fois moins de temps qu’avec la méthode classique.
              Vos devis sont prêts en quelques minutes plutôt qu’en quelques
              heures. Les données récoltées sont liées par glisser-déposer dans
              notre logiciel et le reste des documents découle de ce devis créé
              ultra rapidement.
            </Paragraph>
          </Container.ContentLeft>
          <Container.ContentRight>
            <Heading3
              css={{
                color: colors.brandColor,
                lineHeight: '120%'
              }}
            >
              Utilisation facilitée en mobilité
            </Heading3>
            <Paragraph
              css={{
                fontSize: fontSizes.medium
              }}
            >
              <strong>Bénéficiez de l’enregistrement automatique</strong> à
              mesure que vous travaillez où que vous soyez. Notre logiciel devis
              et facture batiment est{' '}
              <strong>
                accessible en ligne à tout moment pour vous faciliter la vie
              </strong>
              . Mobilité (disponible sur ordinateur, tablette et smartphone) et
              gain de temps sont les 2 grands axes de ce logiciel batiment en
              constante évolution grâce à nos équipes techniques qui améliorent
              continuellement la plateforme.
            </Paragraph>
            <Heading3
              css={{
                color: colors.brandColor,
                lineHeight: '120%'
              }}
            >
              Des mises à jour régulières et gratuites
            </Heading3>
            <Paragraph
              css={{
                fontSize: fontSizes.medium,
                marginBottom: 10
              }}
            >
              <strong>Toutes nos mises à jour sont gratuites </strong>. Nous
              vous guidons via des tutos vidéos à chaque nouveauté.
            </Paragraph>
            <Paragraph
              css={{
                fontSize: fontSizes.medium
              }}
            >
              <strong>
                Web2vi c’est LE logiciel de devis et facture batiment
              </strong>{' '}
              qui convient aux entreprises modernes d’artisans du batiment.
              L’innovation est couplée à l’expertise métier avec nos
              bibliothèques d’ouvrages ultra-complètes créées spécialement par
              et pour les professionnels du batiment.
            </Paragraph>
          </Container.ContentRight>
        </Container>
      </div>
      <div
        css={{
          backgroundColor: colors.neutralGray,
          color: colors.neutralBlack
        }}
      >
        <Heading2
          light
          css={mq({
            textAlign: 'center',
            margin: 'auto',
            paddingTop: 80,
            color: colors.neutralBlack
          })}
        >
          Un <span>logiciel devis batiment avec bibliothèque</span>
          <br /> pour une précision inégalée
        </Heading2>
        <Container css={mq({ flexDirection: ['row', 'column'] })}>
          <Container.ContentLeft css={mq({ paddingRight: [60, 0] })}>
            <Heading3
              css={{
                color: colors.brandColor,
                lineHeight: '120%'
              }}
            >
              Une expertise du milieu du batiment imbattable
            </Heading3>
            <Paragraph
              css={{
                fontSize: fontSizes.medium
              }}
            >
              Dans notre{' '}
              <strong>logiciel devis batiment avec bibliothèque</strong> pour le
              chiffrage vous trouverez des ouvrages conformes aux DTU avec leur
              matériaux et le temps main d’oeuvre associé à prévoir. Tout est
              calculé automatiquement.Etant donné que Web2vi est un logiciel
              conçu spécifiquement pour les entreprises du batiment vous
              réalisez, grâce à notre bibliothèque de prix, vos devis en
              quelques minutes seulement.
            </Paragraph>{' '}
            <Paragraph
              css={{
                fontSize: fontSizes.medium
              }}
            >
              En très bon <strong>logiciel chiffrage batiment</strong>, Web2vi,
              avec le concours de professionnels du bâtiment, a composé ses
              ouvrages de nombreux matériaux appelés “ressources” dans nos bases
              de données. Il y en a plus de 60 000 pour 15 000 ouvrages au
              total. Toutes les ressources sont personnalisables pour y intégrer
              plusieurs de vos prix et enregistrer vos divers fournisseurs.
            </Paragraph>
            <Paragraph
              css={{
                fontSize: fontSizes.medium
              }}
            >
              {' '}
              Web2vi grâce à ses nombreux partenaires dans le milieu du bâtiment
              fait de{' '}
              <strong>son logiciel devis batiment avec bibliothèque</strong> un
              vrai atout spécialisé, du métré à la facturation en passant par la
              gestion de chantier. Vous retrouverez donc les produits d’un très
              grand nombre de fabricants comme Terreal, Unilin, Velux, Isover,
              Bacacier, Imerys, Cupa parmi tant d’autres ainsi que vos
              fournisseurs et coopératives préférées comme SMT, Brelat,
              Chavigny, Orcab etc.
            </Paragraph>
          </Container.ContentLeft>
          <Container.ContentRight>
            <Heading3
              css={{
                color: colors.brandColor,
                lineHeight: '120%'
              }}
            >
              Trouvez votre corps de métier dans{' '}
              <strong>notre bibliothèque de prix batiment</strong>
            </Heading3>
            <Paragraph
              css={{
                fontSize: fontSizes.medium
              }}
            >
              Nos bases de données spécialisées dans le batiment font de Web2vi
              le <strong> logiciel chiffrage batiment</strong> le plus pertinent
              des artisans du btp. Divisées en plusieurs bases de données, nos
              bibliothèques de prix représentent les métiers du clos-couvert
              avec la couverture, zinguerie, charpente bois et ossature,
              étanchéité et bientôt de nombreux autres métiers du BTP comme
              chauffage, climatisation, isolation, maçonnerie, taille de pierre,
              terrassement, plomberie/sanitaires, électricité, ventilation,
              carrelage, menuiserie, plâtrerie ou encore peinture.
            </Paragraph>
            <Heading3
              css={{
                color: colors.brandColor,
                lineHeight: '120%'
              }}
            >
              Créez votre bibliothèque d’ouvrages privée
            </Heading3>
            <Paragraph
              css={{
                fontSize: fontSizes.medium
              }}
            >
              Dès l’achat d’une de nos bibliothèques d’ouvrage ou de
              l’abonnement à notre plateforme, chaque client de Web2vi peut
              créer sa base de données privée. Si vous aviez une bibliothèque
              d’ouvrages déjà constituée vous pouvez ainsi compléter les bases
              de données de <strong>notre logiciel chiffrage batiment</strong>.
            </Paragraph>
          </Container.ContentRight>
        </Container>
      </div>
    </>
  );
};

export default Referencement;
